import React from 'react';
import Video from '../Video';
import style from '../../../src/styles/pages/musicVideos/musicVideos.module.css'


const MusicVideos = () => {

  return (
    <>
      <div className={style.mainContainer}>
        <div>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
        </div>

        <div className={style.videoContainer}>
        <Video videoId="Y2dHuUEqGvA" />
        </div>

        <div>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
        </div>
      </div>
      <div className={style.mainContainer}>
      <div>
        <p className={style.awardSign}>( )</p>
        <p className={style.awardSign}>( )</p>
        <p className={style.awardSign}>( )</p>
      </div>

      <div className={style.videoContainer}>
       <Video videoId="HkV1ty7IxfM" />
      </div>

      <div>
        <p className={style.awardSign}>( )</p>
        <p className={style.awardSign}>( )</p>
        <p className={style.awardSign}>( )</p>
      </div>
    </div>
    <div className={style.mainContainer}>
      <div>
        <p className={style.awardSign}>( )</p>
        <p className={style.awardSign}>( )</p>
        <p className={style.awardSign}>( )</p>
      </div>

      <div className={style.videoContainer}>
       <Video videoId="Q-IbX1znt3c" />
      </div>

      <div>
        <p className={style.awardSign}>( )</p>
        <p className={style.awardSign}>( )</p>
        <p className={style.awardSign}>( )</p>
      </div>
    </div>
    </>
  );
  
};

export default MusicVideos;