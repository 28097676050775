import React from 'react'
import Navigation from './navigation/Navigation';


export default function App() {
  return (
    <>
      <Navigation />
    </>
  )
}
