import React, { useState } from 'react';
import Logo from '../../assets/home-page/logo.svg';

export default function SignIn() {
  // TEMP PAGE

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
      }}
    >
      <div style={{ marginTop: '11%' }}>
        <h1
          style={{
            fontFamily: 'niveau-grotesk, sans-serif',
            fontSize: '40px',
            fontWeight: 700,
            fontStyle: 'normal',
            marginBottom: 0,
            color: 'rgb(132, 132, 132)',
            textAlign: 'center',
            }}
          >
          Sign-In Functionality Coming Soon
        </h1>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              animation: 'fadeOut 2s forwards',
            }}
            className="logo-container"
          >
            <img
              style={{ width: '250px', transform: 'rotate(5deg)' }}
              className="logo"
              src={Logo}
              alt="logo"
            />
          <div
            style={{
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            className="content-container"
          >    
          </div>
        </div>
      </div>
    </div>
  );
}