import React from 'react';
import Logo from '../../assets/home-page/logo-with-glow.png'
import { Link } from 'react-router-dom';
import style from '../../../src/styles/pages/services/services.module.css'

function ServicePage() {

    return (
        <div className={style.servicePage}>
            <div className={style.backgroundImage}></div>
            <main className={style.mainContent}>
                <h3 className={style.headingTop}>Drive interest in your product, service, or event with a premium, festival-quality production.</h3>
                <h3 className={style.headingCenter}>Experience the revolutionary new way to get a video produced. Tech-enabled automated processes. Seamless team assemblage. Cinema-quality experience.</h3>
                <h3 className={style.headingEnd}>The perfect process at your fingertips augmented by trained and up-and-coming filmmakers. Get started now.</h3>
                <div className={style.diagramContainer}>
                    <div className={style.diagram}>
                        <div className={style.logoContainerServices}>
                            <div className={style.logoBg}>
                                <img className={style.logoServices} src={Logo} alt="logo" />
                            </div>
                        </div>
                        <div className={style.buttonContainer}>
                            <div className={style.buttonRow}>
                                <div className={style.line1}></div>
                                <Link to="/music-videos">
                                    <button className={style.diagramButton}>Music Video</button>
                                </Link>
                            </div>
                            <div className={style.buttonRow}>
                                <div className={style.line2}></div>
                                <Link to="/immersive-ads">
                                    <button className={style.diagramButton}>Immersive Ads</button>
                                </Link>
                            </div>
                            <div className={style.buttonRow}>
                                <div className={style.line3}></div>
                                <Link to="/wedding-experience">
                                    <button className={style.diagramButton}>Wedding Experience</button>
                                </Link>
                            </div>
                            <div className={style.buttonRow}>
                                <div className={style.line4}></div>
                                <Link to="/services/freelancer-categories/composers">
                                    <button className={style.diagramButton}>Royalty-Free Music</button>
                                </Link>
                            </div>
                            <div className={style.buttonRow}>
                                <div className={style.line5}></div>
                                <Link to="/services/freelancer-categories">
                                    <button className={style.diagramButton}>Hire Freelancers</button>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>

            </main>
        </div>
    );
}

export default ServicePage;