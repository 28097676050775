import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import './films.module.css';

// Define the onYouTubeIframeAPIReady function at the global scope
window.onYouTubeIframeAPIReady = function() {
  // This function will be called by the YouTube API when it's ready
  // You can initialize your YouTube player here if needed
};

const Video = ({ videoId }) => {

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);


    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  const opts = {
    height: screenSize.height - 200,
    width: "100%",
    playerVars: {
      autoplay: 1, // Enable autoplay
      cc_lang_pref: "en", // Set closed captions language preference
      cc_load_policy: 1, // Enable closed captions by default
      color: "red", // Set the color of the video controls
      controls: 1, // Show video controls
      disablekb: 0, // Enable keyboard controls
      enablejsapi: 1, // Enable JavaScript API
      end: 0, // Set the end time of the video
      fs: 1, // Enable fullscreen button
      hl: 'en', // Set the player's interface language
      iv_load_policy: 1, // Show annotations
      key: process.env.REACT_YOUTUBE_API_KEY, // set api key from env variables
      list: undefined, // Set the playlist ID
      listType: undefined, // Set the type of playlist (e.g., playlist, search, user_uploads)
      loop: 0, // Disable video looping
      modestbranding: 1, // Hide the YouTube logo in the control bar
      origin: undefined, // Set the origin parameter for API requests
      playlist: undefined, // Set the playlist or video IDs to play
      playsinline: 1, // Enable inline playback on iOS
      rel: 1, // Enable related videos at the end
      start: 0, // Set the start time of the video
      widget_referrer: undefined, // Set the referrer parameter for widget API requests
    },
  };

  const handleVideoPause = (event) => {
    console.log('Video paused:', event.target);
  };

  const handleVideoEnd = (event) => {
    console.log('Video ended:', event.target);
  };

  const handleVideoError = (event) => {
    console.error('Video error:', event.target);
  };

  const handlePlaybackRateChange = (event) => {
    console.log('Playback rate changed:', event.target);
  };

  const handlePlaybackQualityChange = (event) => {
    console.log('Playback quality changed:', event.target);
  };


  return (
    <YouTube
      videoId={videoId}
      id="my-youtube-video"
      className="my-youtube-video-class"
      iframeClassName="my-youtube-iframe-class"
      style={{ width: "100%" }}
      title="My YouTube Video"
      loading="lazy"
      onPause={handleVideoPause}
      onEnd={handleVideoEnd}
      onError={handleVideoError}
      onPlaybackRateChange={handlePlaybackRateChange}
      onPlaybackQualityChange={handlePlaybackQualityChange}
      opts={opts}
    />
  );
};

export default Video;