import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "../pages/homePage/Homepage";
import SignIn from"../pages/signIn/SignIn";
import Layout from "../components/layout/Layout";
import ServicePage from "../pages/services/Services";
import MusicVideos from "../pages/musicVideos/MusicVideos";
import ImmersiveAds from "../pages/immersiveAds/ImmersiveAds";
import WeddingExperience from "../pages/weddingExperience/WeddingExperience";
import Films from "../pages/films/Films"
import FilmsSlave from "../pages/films/FilmsSlave"
import FilmsMHAFanFilm from "../pages/films/FilmsMHAFanFilm"
import FreeLancers from "../pages/freeLancers/FreeLancers";
import Composers from "../pages/composers/Composers";


function Navigation() {

  return (
    <div className="App">
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/films" element={<Films />} />
            <Route path="/films/slave" element={<FilmsSlave />} />
            <Route path="/films/mha-fan-film" element={<FilmsMHAFanFilm />} />
            <Route path="/services" element={<ServicePage />} />
            <Route path="/music-videos" element={<MusicVideos />} />
            <Route path="/immersive-ads" element={<ImmersiveAds />} />
            <Route path="/wedding-experience" element={<WeddingExperience />} />
            <Route path="/services/freelancer-categories" element={<FreeLancers />} />
            <Route path="/services/freelancer-categories/composers" element={<Composers />} />
            <Route path="/services/freelancer-categories/directors" element={<Composers />} />
            <Route path="/services/freelancer-categories/cine-dept" element={<Composers />} />
            <Route path="/services/freelancer-categories/editors-vfx" element={<Composers />} />
            <Route path="/services/freelancer-categories/pd-dept" element={<Composers />} />
            <Route path="/services/freelancer-categories/colorists" element={<Composers />} />
          </Routes>
        </Layout>
      </Router>
    </div>

  )}
  
export default Navigation;