import React, { useEffect, useState } from 'react';
import './HomePage.css';
import Logo from '../../assets/home-page/logo.svg';
import Seats from '../../assets/home-page/seats.svg';
import Stars from '../../assets/home-page/stars.png';
import StarsMobile from '../../assets/home-page/stars.png';
import { Link } from 'react-router-dom';


export default function Homepage() {
    
    const [isClick, setIsClick] = useState(false);
    setTimeout(() => {
        setIsClick(true);
    }, 8000);

    useEffect(() => {
        showTime()
    }, []);

    function showTime() {
        var curtain = document.getElementById('curtain');
        curtain.classList.add('open');

        var scene = document.getElementById('scene');
        scene.classList.add('expand');

    }

    return (
        <div className="cinema-container">
            <div className="ceiling">
                <div className="light-image_container" >
                    <img className="light-image" src={Stars} alt="Lights" />
                    <img className="light-image_mobile" src={StarsMobile} alt="Lights" />
                </div>
            </div>

            <div className="signin-button">Sign In</div>
            <Link className='link' to="/sign-in">
                <div className="signin-button">Sign In</div>
            </Link>
            <div id="scene">
                <div id="curtain">
                    <div className="logo-container">
                        <img className="logo" src={Logo} alt="logo" />
                        <div className="content-container">
                            <Link className='link' to="/films">
                                <div className="first button">
                                    Films
                                </div>
                            </Link>
                            <div className="text-container">
                                <h1 className="title">Frequency Optics</h1>
                                <p className="description">Consistent quality films</p>
                            </div>
                            <Link className='link' to="/services">
                                <div className="button">
                                    Services
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="seat-container">
                        <img className="seats" src={Seats} alt="logo" />
                    </div>
                    <div className="left"></div>
                    <div className="right"></div>
                </div>
            </div>
        </div>
    );
}