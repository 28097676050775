import React from 'react';
import Video from './Video';
import style from './films.module.css'
import Award1 from '../../assets/films/awards/slave-filmhaus-award.png'
import Award2 from '../../assets/films/awards/slave-nyifa-award.png'
import Award3 from '../../assets/films/awards/slave-oregon-screams-award.png'

const FilmsSlave = () => {
  const award1Style = {
    backgroundImage: 'url("../../assets/films/awards/Slave-NYIFA-Award-2023.png")',
    height: '150px',
    width: '300px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '2px solid white'
  }

  const awardImageStyle = {
    height: '10vh',
    width: 'auto', // Maintain aspect ratio
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginBottom: '0px'
  };

  return (
    <>
      <div className='three-awards' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', backgroundColor: 'black' }}>
        <img src={Award1} style={awardImageStyle} alt="Award 1" />
        <img src={Award2} style={awardImageStyle} alt="Award 2" />
        <img src={Award3} style={awardImageStyle} alt="Award 3" />
      </div>
        <div className={style.mainContainer}>
        <div className={style.videoContainer}>
          <Video videoId="anRoaXoHFrY" />
        </div>
      </div>
    </>
  );
  
};

export default FilmsSlave;