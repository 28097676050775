import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BiHomeAlt } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import style from '../../../src/styles/components/header/Header.module.css'

const Header = () => {
    const location = useLocation();
    const isServicePage = location.pathname === '/services';

    const formatPathname = (pathname) => {
        const pathParts = pathname.split('/').filter(part => part);
        const lastPart = pathParts[pathParts.length - 1];
        return lastPart.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    const pageTitle = formatPathname(location.pathname);

    return (
        <header className={style.header}>
            <nav className={style.navigation}>
                <Link to="/">
                    <BiHomeAlt className={style.homeIcon} />
                </Link>
                {(location.pathname === '/films/mha-fan-film' || location.pathname === '/films/slave') && <Link to="/films" className={style.navLinks}>
                    Films
                </Link>}

                {(location.pathname === '/music-videos' || location.pathname === '/immersive-ads' || location.pathname === '/wedding-experience' || location.pathname === '/wedding-experience') && <Link to="/services" className={style.navLinks}>
                    Services
                </Link>}

                {location.pathname === '/services/freelancer-categories/composers' && <Link to="/services/freelancer-categories" className={style.navLinks}>
                    Freelancers
                </Link>}

                {<div className={style.selectedValueContainer2}>
                <div className={style.dotContainer}>
                    <div className={style.dot}></div>
                    <h1 className={style.selectedValue}>{pageTitle}</h1>
                </div>
            </div>}
            </nav>

            {<div className={style.selectedValueContainer}>
                <div className={style.dotContainer}>
                    <div className={style.dot}></div>
                    <span className={style.selectedValue}>{pageTitle}</span>
                </div>
            </div>}
            {(location.pathname === '/services/freelancer-categories' || location.pathname === '/services/freelancer-categories/composers' ) && <CgProfile className={style.profileIcon} />}
        </header>
    );
}

export default Header;
