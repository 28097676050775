import React from 'react';
import Video from '../Video';
import style from '../../../src/styles/pages/immersiveAds/immersiveAds.module.css'


const ImmersiveAds = () => {

  return (
    <>
      <div className={style.mainContainer}>
        <h1>HELLO</h1>
        <div>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
        </div>

        <div className={style.videoContainer}>
        <Video videoId="AsHmwpEY4mA" />
        </div>

        <div>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
        </div>
      </div>
      <div className={style.mainContainer}>
        <h1>HELLO</h1>
        <div>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
        </div>

        <div className={style.videoContainer}>
        <Video videoId="b-oMQRHiQy0" />
        </div>

        <div>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
        </div>
      </div>
      <div className={style.mainContainer}>
        <h1>HELLO</h1>
        <div>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
        </div>

        <div className={style.videoContainer}>
        <Video videoId="o3x5P6Y-BnM" />
        </div>

        <div>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
        </div>
      </div>
      <div className={style.mainContainer}>
        <h1>HELLO</h1>
        <div>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
        </div>

        <div className={style.videoContainer}>
        <Video videoId="RnR6GOG4HNY" />
        </div>

        <div>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
        </div>
      </div>
    </>
  );
  
};

export default ImmersiveAds;