import React from 'react';
import Video from '../Video';
import style from '../../../src/styles/pages/weddingExperience/weddingExperience.module.css'


const WeddingExperience = () => {

  return (
    <>
      <div className={style.mainContainer}>
        <h1>HELLO</h1>
        <div>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
        </div>

        <div className={style.videoContainer}>
        <Video videoId="vyNrLKzRi5I" />
        </div>

        <div>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
          <p className={style.awardSign}>( )</p>
        </div>
      </div>
    </>
  );
  
};

export default WeddingExperience;