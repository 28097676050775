import React, { useState } from 'react';
import style from '../../../src/styles/pages/composers/composers.module.css';
import { AiOutlineSearch, AiOutlineClose } from 'react-icons/ai';
import { GrPlayFill } from 'react-icons/gr';
import { BsFillPauseFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import composersInfo from '../../constants/ComposerInfo';
import composersTags from '../../constants/ComposerTags';
import Logo from '../../assets/home-page/logo.svg';

export default function Composer() {
  // TEMP PAGE

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
      }}
    >
      <div style={{ marginTop: '11%' }}>
        <h1
          style={{
            fontFamily: 'niveau-grotesk, sans-serif',
            fontSize: '40px',
            fontWeight: 700,
            fontStyle: 'normal',
            marginBottom: 0,
            color: 'rgb(132, 132, 132)',
            textAlign: 'center',
            }}
          >
          Coming Soon
        </h1>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              animation: 'fadeOut 2s forwards',
            }}
            className="logo-container"
          >
            <img
              style={{ width: '250px', transform: 'rotate(5deg)' }}
              className="logo"
              src={Logo}
              alt="logo"
            />
          <div
            style={{
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            className="content-container"
          >    
          </div>
        </div>
      </div>
    </div>
  );

  // ACTUAL PAGE 
  /*

  const [currentSongs, setCurrentSongs] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState(composersTags);

  const handlePlayAudio = (index, songIndex) => {
    const currentSongIndex = currentSongs[index];

    if (currentSongIndex === songIndex) {
      const audioPlayer = document.getElementById(`audioPlayer-${index}-${songIndex}`);
      if (audioPlayer.paused) {
        audioPlayer.play();
        setCurrentSongs({ ...currentSongs, [index]: songIndex });
      } else {
        audioPlayer.pause();
        setCurrentSongs({ ...currentSongs, [index]: null });
      }
    } else {
      const previousSongIndex = currentSongIndex !== undefined ? currentSongIndex : null;
      const previousAudioPlayer = document.getElementById(`audioPlayer-${index}-${previousSongIndex}`);
      const currentAudioPlayer = document.getElementById(`audioPlayer-${index}-${songIndex}`);

      if (previousAudioPlayer) {
        previousAudioPlayer.pause();
        previousAudioPlayer.currentTime = 0;
      }

      if (currentAudioPlayer) {
        currentAudioPlayer.play();
        setCurrentSongs({ ...currentSongs, [index]: songIndex });
      }
    }
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
  
    // Filter the tags based on the search term
    const filteredTags = composersTags.filter((tag) =>
      tag.name.toLowerCase().includes(searchTerm)
    );
  
    // Move the "Action" tag to the top if it matches the search term
    const actionTagIndex = filteredTags.findIndex((tag) => tag.name.toLowerCase() === "action");
    if (actionTagIndex !== -1) {
      const actionTag = filteredTags.splice(actionTagIndex, 1)[0];
      filteredTags.unshift(actionTag);
    }
  
    setFilteredTags(filteredTags);
  };

  const filteredTagsDisplayed = filteredTags.filter((tag) =>
    tag.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [currentSelection, setCurrentSelection] = useState(null);
  const handleTagSelection = (index) => {
    const selectedTag = filteredTagsDisplayed[index];
    const isSelected = selectedTag.isSelected;
  
    // Check if the tag is already selected
    if (isSelected) {
      // Tag is already selected, return without making any changes
      return;
    }
  
    // Clone the selectedTags array
    const updatedSelectedTags = [...selectedTags];
  
    // Add the tag to selectedTags
    updatedSelectedTags.push(selectedTag);
  
    // Toggle the isSelected property for the selected tag
    const updatedFilteredTags = filteredTagsDisplayed.map((tag, idx) => {
      if (idx === index) {
        return { ...tag, isSelected: true };
      }
      return tag;
    });
  
    // Update the state with the new arrays
    setSelectedTags(updatedSelectedTags);
    setFilteredTags(updatedFilteredTags);
  
    // Update the current selection
    setCurrentSelection(index);
  };
  
  
  

  const handleTagRemoval = (index) => {
    const updatedTags = [...selectedTags];
    updatedTags.splice(index, 1);
    setSelectedTags(updatedTags);
  
    // Update the isSelected property for the removed tag in filteredTags
    const updatedFilteredTags = filteredTags.map((tag) => {
      if (tag.id === selectedTags[index].id) {
        return { ...tag, isSelected: false };
      }
      return tag;
    });
    setFilteredTags(updatedFilteredTags);
  };

  return (
    <div className={style.container}>
      <div className={style.main}>
        <div className={style.sidebar}>
          <p className={style.sidebarTitle}>Browse By<br /> Tags</p>
          <div className={style.inputContainer}>
            <AiOutlineSearch className={style.searchIcon} />
            <input
              className={style.input}
              type='text'
              placeholder='Search for more tags'
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          <div className={style.imageMain}>
            {filteredTagsDisplayed.map((item, index) => (
              <div
                onClick={() => handleTagSelection(index)}
                className={style.imageContainer}
                key={index}
              >
                <div className={style.bgImage} style={{ backgroundImage: `url(${item.image})` }}></div>
                
                <div className={style.oval}>
                        </div>
                <Link to={item?.redirect} className={style.linkText}>
                  {item.name}
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className={style.content}>
          <p className={style.contentTitle}>Composers</p>
          <div className={style.tagsContainer}>
            {selectedTags.map((tag ,index) => (
              <div className={style.tag} key={tag.id}>
                <p className={style.tagTitle}>{tag.name}</p>
                <AiOutlineClose
                  className={style.closeIcon}
                  onClick={() => handleTagRemoval(index)}
                />
              </div>
            ))}
          </div>

          <div className={style.songsCardContainer}>
            {composersInfo.map((item, index) => (
              <div key={index} className={style.songsCard} style={{ backgroundImage: `url(${item.backgroundImage})` }}>
                <div className={style.innerContainer}>
                  <div className={style.cardImage}>
                    {item.profileImage && <img className={style.cardImage2} src={item.profileImage} alt={item.profileImage} />}
                  </div>
                  <div
                    style={{ opacity: item.song1Title ? 0.8 : 1 }}
                    className={style.song1Container}
                    onClick={() => handlePlayAudio(index, 1)}
                  >
                    {item.song1Title && (currentSongs[index] === 1 ? (
                      <BsFillPauseFill className={style.pauseIcon} />
                    ) : (
                      <GrPlayFill className={style.playIcon} />
                    ))}
                    <p className={style.songTitle}>{item.song1Title && item.song1Title}</p>
                    <audio id={`audioPlayer-${index}-1`} src={item.featuredSong1} />
                  </div>
                  <div
                    style={{ opacity: item.song2Title ? 0.8 : 1 }}
                    className={style.song1Container}
                    onClick={() => handlePlayAudio(index, 2)}
                  >
                    {item.song2Title && (currentSongs[index] === 2 ? (
                      <BsFillPauseFill className={style.pauseIcon} />
                    ) : (
                      <GrPlayFill className={style.playIcon} />
                    ))}
                    <p className={style.songTitle}>{item.song2Title && item.song2Title}</p>
                    <audio id={`audioPlayer-${index}-2`} src={item.featuredSong2} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

  )
                      */
}