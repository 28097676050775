import React from 'react'
import style from '../../styles/pages/freeLancers/FreeLancers.module.css'
import { Link } from 'react-router-dom';
import Image1 from '../../../src/assets/freelancer/cinema.jpeg'
import Image2 from '../../../src/assets/freelancer/camera.webp'
import Image3 from '../../../src/assets/freelancer/dummy.jpeg'
import Image4 from '../../../src/assets/freelancer/dummy2.webp'
import Image5 from '../../../src/assets/freelancer/dummy3.webp'
import Image6 from '../../../src/assets/freelancer/dummy4.jpeg'


export default function FreeLancers() {

    const imageData = [

        { image: Image1, text: 'Composers', redirect: "/services/freelancer-categories/composers" },
        { image: Image2, text: 'Directors', redirect: "/services/freelancer-categories/directors" },
        { image: Image3, text: 'Cine Dept', redirect: "/services/freelancer-categories/cine-dept" },
        { image: Image4, text: 'Editors/VFX', redirect: "/services/freelancer-categories/editors-vfx" },
        { image: Image5, text: 'PD Dept.', redirect: "/services/freelancer-categories/pd-dept" },
        { image: Image6, text: 'Colorists', redirect: "/services/freelancer-categories/colorists" },

    ];

    return (
        <div className={style.container}>
            <div className={style.imageMain}>
                {imageData.map((item, index) => (
                    <div className={style.imageContainer} key={index}>
                        <div className={style.bgImage} style={{ backgroundImage: `url(${item.image})` }}></div>
                        <div className={style.oval}>
                        </div>
                        <Link to={item?.redirect} className={style.linkText}>
                            {item.text}
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}