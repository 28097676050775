import React from 'react';
import { Link } from 'react-router-dom';
import style from './films.module.css'
import Logo from '../../assets/home-page/logo.svg';
import SlaveThumbnail from '../../assets/films/slave-thumbnail.png'
import MHAFanFilmThumbnail from '../../assets/films/MHA-fan-film-thumbnail.png'

const Films = () => {

    // Sample local image file names (assuming they are in the public/images directory)
  const thumbnailLinks = [
    { thumbnail: SlaveThumbnail, link: "/films/slave" },
    { thumbnail: MHAFanFilmThumbnail, link: "/films/mha-fan-film" }
  ]
  
  const handleThumbnailClick = (imageName) => {
    // Handle the click event here, e.g., open a larger version of the image
    console.log(`Thumbnail clicked: ${imageName}`);
  };

  return (
    <main className="content-container" style={{ backgroundColor: '#001010', justifyContent: 'center', width: '100%', height: '82vh', display: 'flex', flexDirection: 'column'}}>
        <img
              style={{ width: '5vw', transform: 'rotate(5deg)', padding: '2vw'}}
              className="logo"
              src={Logo}
              alt="logo"
            />
      <h1 style={{ marginTop: '0px', color: 'gray' }}>Frequency Optics Films</h1>
      <section className="thumbnail-list" style={{ height: '56vh', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
        {thumbnailLinks.map(({thumbnail, link}, index) => (
          <Link to={link}>
            <img
            key={index}
            src={`${thumbnail}`} // Reference the local image files
            alt={`Thumbnail ${index}`}
            onClick={() => handleThumbnailClick(thumbnail)}
            style={{height: '10vw', margin: '3vw'}}
            />
          </Link>
        ))}
      </section>
    </main>
  );



// TEMP PAGE


// COMING SOON
/*
return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
      }}
    >
      <div style={{ marginTop: '11%' }}>
        <h1
          style={{
            fontFamily: 'niveau-grotesk, sans-serif',
            fontSize: '40px',
            fontWeight: 700,
            fontStyle: 'normal',
            marginBottom: 0,
            color: 'rgb(132, 132, 132)',
            textAlign: 'center',
            }}
          >
          Coming Soon
        </h1>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              animation: 'fadeOut 2s forwards',
            }}
            className="logo-container"
          >
            <img
              style={{ width: '250px', transform: 'rotate(5deg)' }}
              className="logo"
              src={Logo}
              alt="logo"
            />
          <div
            style={{
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            className="content-container"
          >    
          </div>
        </div>
      </div>
    </div>
  );*/

  
};

export default Films;