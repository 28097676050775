import React from 'react';
import style from '../../../src/styles/components/footer/footer.module.css'

const Footer = () => {

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return (
        <div className={style.main}>
            <p className={style.date}>COPYRIGHT © {currentYear} Frequency Optics. ALL RIGHTS RESERVED. </p>
        </div>
    );

}

export default Footer;