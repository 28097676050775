import React from 'react';
import Video from './Video';
import style from './films.module.css'
import { useState } from 'react';
import MHATitle from '../../../src/assets/films/My-Hero-Academia-Logo.png'
import MHAPoster from '../../../src/assets/films/MHA-Poster-Darkened.png'

const FilmsSlave = () => {

  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your logic here to handle the submission, e.g., sending the email to a server
    setIsSubmitted(true);
  };

  const containerStyle = {
    backgroundImage: `url(${MHAPoster})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundSize: '115% auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '81.5vh',
    width: '78vw',
  };

  return (

    
    <main style={{ backgroundColor: "#000100", width: "100vw", height: "82vh", display: "flex", flexDirection: "row"}}>,
        <div style={containerStyle}>
          <img
              style={{ width: '250px', marginBottom: '0vw', paddingBottom: '0vw' }}
              src={MHATitle}
              alt="logo"
          />
          <h1 style={{ color: 'red', alignSelf: 'center', margin: '0px' }}>My Hero Academia Fan Film</h1>
          <div className="email-notification-form">
            <h2 style={{ color: 'white' }}>Enter your email to be notified on launch!</h2>
            <div>
              <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <input
    type="email"
    placeholder="Enter your email here"
    value={email}
    onChange={handleEmailChange}
    style={{ padding: '10px 30px', fontSize: '18px', marginBottom: '10px' }}
/>
<button type="submit" style={{ padding: '10px 20px', fontSize: '18px', marginLeft: "0.4vw", marginBottom: '10px' }}>Submit</button>

              </form>
            </div>
            {isSubmitted && <p style={{color: 'white'}}>Thank you! We will notify you on launch.</p>}
          </div>
        </div>
        <div className={style.mainContainer}>
            <div className={style.videoContainer}>
                <Video videoId="6JV7PIbCxfM" />
            </div>
        </div>
    </main>
  );
  
};

export default FilmsSlave;